import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { CircleButton } from '../Button/CircleButton.js';
import { constants } from '../utils/styles/constants.js';

const StyledContainer = styled.div.withConfig({
  componentId: "sg16120__sc-1u6bxvw-0"
})(["display:flex;", ""], props => props.$isCentered && css(["justify-content:center;"]));
const StyledRating = styled.div.withConfig({
  componentId: "sg16120__sc-1u6bxvw-1"
})(["display:flex;flex-direction:column;"]);
const StyledValues = styled.div.withConfig({
  componentId: "sg16120__sc-1u6bxvw-2"
})(["", " ", " display:flex;flex-direction:column;"], props => props.$isCentered && css(["align-self:center;"]), props => props.$isCentered === false && css(["align-self:flex-start;"]));
const arrowSvg = encodeURIComponent("<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 36 14\"><path d=\"M.53 13.49c3.37 0 4.17-.19 5-1l9-10a4.83 4.83 0 0 1 7 0l9 10c.8.8 1.6 1 4.94 1L35 15H1z\" fill=\"#fff\"/><path d=\"M38.5 13.5c-6 0-7 0-8-1l-9-10a4.83 4.83 0 0 0-7 0l-9 10c-1 1-2 1-8 1\" fill=\"none\" stroke=\"".concat(tokens.color['neutral-4'], "\" stroke-miterlimit=\"10\" /></svg>"));
const StyledCircleButton = styled(CircleButton).withConfig({
  componentId: "sg16120__sc-1u6bxvw-3"
})(["", " ", ""], props => props.$isSmall && css(["font-size:", ";height:1.5rem;width:1.5rem;"], tokens['font-size'].sm), props => props.$hasArrow && css(["&::before{background-image:url('data:image/svg+xml;,", "');bottom:-", "px;content:'';height:", ";left:50%;position:absolute;transform:translateX(-50%);width:36px;}"], arrowSvg, parseInt(constants.dropdownArrowHeight) + 2, constants.dropdownArrowHeight));
const StyledOptionLabel = styled.div.withConfig({
  componentId: "sg16120__sc-1u6bxvw-4"
})(["background-color:", ";border-radius:", ";border:1px solid ", ";margin-top:", ";padding:", " ", ";text-align:center;"], tokens.color.white, tokens.radius.md, tokens.color['neutral-4'], constants.dropdownArrowHeight, tokens.space.xs, tokens.space.sm);

export { StyledCircleButton, StyledContainer, StyledOptionLabel, StyledRating, StyledValues };
